import { statusActions } from "../../../components/grid-views/status-actions";
import i18n from "@/plugins/i18n";

export const columns = [
  {
    label: '#',
    field: (value) => {
      return value.originalIndex + 1;
    },
    width: '2%',
    filterOptions: {
      enabled: false,
    },
  },
  {
    label: 'orderNumber',
    field: 'orderNumber',
    width: '5%',
    sortable: false,
    filterOptions: {
      enabled: false,
    },
  },
  {
    label: 'address',
    field: (value) => {
      return value.forms.length>0 ? value.forms[0].address : '';
    },
    width: '38%',
    sortable: false,
    filterOptions: {
      enabled: false,
    },
  },
  {
    label: 'phone',
    field: (value) => {
      return value.forms.length>0 ? value.forms[0].phone : '';
    },
    width: '16%',
    sortable: false,
    filterOptions: {
      enabled: false,
    },
  },
  {
    label: 'manager',
    field: 'managers.id',
    formatRowValue: true,
    formatFn: (value) => {
      return value.managers.length > 0 ? value.managers[0].title : '';
    },
    width: '10%',
    sortable: false,
    filterOptions: {
      enabled: false,
    },
  },
  {
    label: 'paymentMethod',
    field: 'paymentMethods.id',
    formatRowValue: true,
    formatFn: (value) => {
      return value.paymentMethods.length>0 ? value.paymentMethods[0].title : '';
    },
    width: '10%',
    sortable: false,
    filterOptions: {
      enabled: false,
    },
  },
  {
    label: 'price',
    field: (value) => {
      return value.price;
    },
    width: '10%',
    sortable: false,
    filterOptions: {
      enabled: false,
    },
  },
  {
    label: '',
    field: 'actions',
    width: '8%',
    sortable: false,
    actions: [
      ...statusActions,
      {
        type: 'view',
        label: '',
        class: 'fa fa-eye',
        action: {
          type: 'route',
          paramsResolver: (data) => {
            return {'id': data.id}
          },
          nameResolver: () => {
            return 'ViewOrder';
          }
        },
      },
    ]
  },
]
