<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <h2>{{ $t('distributionList') }}</h2>
      <b-row class="mt-3">
        <b-col md="4" lg="3" sm="12">
          <b-form-select
            v-model="driver"
            :options="drivers"
          />
        </b-col>
        <b-col md="4" lg="3" sm="12">
          <b-form-select
            v-model="manager"
            :options="managers"
          />
        </b-col>
        <b-col md="4" lg="2" sm="12">
          <date-picker
            v-model="date"
            :config="dateOptions"
          />
        </b-col>
        <b-col md="4" lg="2" sm="12">
          <b-button
            block
            variant="primary"
            @click="searchOrders"
          >
            {{ $t('search') }}
          </b-button>
        </b-col>
        <b-col md="4" lg="2" sm="12">
          <b-button class="w-100 bg-success" variant="link">
            <a class="v-auth-href" v-auth-href :href="exportUrl">
              <span class="fa fa-file-excel-o" /> {{ $t('ExcelExport') }}
            </a>
          </b-button>
        </b-col>
      </b-row>
      <grid-view-table
        v-if="date"
        id="distribution"
        :columns="columns"
        :path="'order'"
        :extra-params="gridViewUrl"
        :per-page="100"
        :default-sort="'createdAt,ASC'"
      />
      <div><b>{{ $t('totalFromDriver') }}:</b> <i>{{ totalPrice }}</i></div>
      <br />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import GridViewTable from '../../components/GridViewTable';
import { columns } from './grid-views/distribution';
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import { APIService } from '../../services/api';
import i18n from "@/plugins/i18n";

export default {
  name: 'DistributionList',
  components: {
    GridViewTable,
    datePicker,
  },
  data: () => {
    return {
      columns,
      drivers: [],
      driver: null,
      managers: [],
      manager: null,
      date: null,
      dateOptions: {
        format: 'DD.MM.YYYY',
        useCurrent: false,
        showClear: true,
        showClose: true,
        locale: 'ru',
      }
    }
  },
  async beforeMount() {
    const grid = this.$store.getters.GET_GRID('distribution');
    if (grid) {
      const filter = grid.serverParams.filters;
      if (filter) {
        if (filter['managers.id']) {
          this.manager = filter['managers.id'];
        }
        if (filter['drivers.id']) {
          this.driver = filter['drivers.id'];
        }
      }
    }
    this.date = this.$store.getters.GET_DISTRIBUTION_DATE;
    try {
      const managers = await APIService.get('manager?sort=title,ASC');
      const drivers = await APIService.get('driver?sort=title,ASC');
      this.drivers = [
        { value: null, text: i18n.t('choseDriver') },
      ].concat(drivers.map(driver => {
        return {
          text: driver.title,
          value: driver.id,
        }
      }));
      this.managers = [
        { value: null, text: i18n.t('choseManager') },
      ].concat(managers.map(manager => {
        return {
          text: manager.title,
          value: manager.id,
        }
      }));
    } catch(e) {
      console.error(e);
    }
  },
  methods: {
    calculateDays() {
      const date = this.$store.getters.GET_DISTRIBUTION_DATE;
      const startDate = moment(date, 'DD.MM.YYYY').startOf('day');
      const endDate = moment(startDate).endOf('day');
      return [startDate.format(), endDate.format()].map(dateTime => encodeURIComponent(dateTime));
    },
    searchOrders() {
      const token = 'distribution';
      const grid = this.$store.getters.GET_GRID(token);
      const date = moment(this.date, 'DD.MM.YYYY').toDate();
      const filter = {};
      if (this.manager) {
        filter['managers.id'] = this.manager;
      }
      if (this.driver) {
        filter['drivers.id'] = this.driver;
      }
      this.$store.dispatch('UPDATE_DISTRIBUTION_DATE', date);
      grid.serverParams = Object.assign({}, grid.serverParams, {filters: filter});
      this.$store.dispatch('UPDATE_GRID', {data: grid, token});
    },
  },
  computed: {
    totalPrice() {
      const grid = this.$store.getters.GET_GRID('distribution');
      const orders = grid ? grid.rows : [];
      return orders
      .filter(order => order.paymentMethods && order.paymentMethods.length>0 && order.paymentMethods[0].title === 'Нал')
      .reduce((n, {price}) => n + price, 0);
    },
    gridViewUrl() {
      const [startDate, endDate] = this.calculateDays();
      let url = '&join=forms&join=managers&join=statuses&join=drivers&join=paymentMethods&join=deliveryDates';
      url += '&filter=statuses.token||$in||during_delivery,delivered';
      const managerUrl = this.filterManager ? `&filter=managers.id||$eq||${this.filterManager}` : '';
      const driverUrl = this.filterDriver ? `&filter=drivers.id||$eq||${this.filterDriver}` : '';
      return url + `&filter=deliveryDates.date||$between||${startDate},${endDate}${managerUrl}${driverUrl}`;
    },
    filterManager() {
      const token = 'distribution';
      const grid = this.$store.getters.GET_GRID(token);
      if (grid) {
        const filter = grid.serverParams.filters;
        if (filter && filter['managers.id']) {
          return filter['managers.id'];
        }
      }
      return null;
    },
    filterDriver() {
      const token = 'distribution';
      const grid = this.$store.getters.GET_GRID(token);
      if (grid) {
        const filter = grid.serverParams.filters;
        if (filter && filter['drivers.id']) {
          return filter['drivers.id'];
        }
      }
      return null;
    },
    exportUrl() {
      const [startDate, endDate] = this.calculateDays();
      return `${APIService.getBaseUrl()}export/logistics/distribution/${startDate}/${endDate}` +
      `/${this.manager}/${this.driver}`;
    }
  },
}
</script>

<style scoped>
  .v-auth-href {
    color: white;
  }
</style>
